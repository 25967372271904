import './App.css';
import MainView from './views/MainView';
import ErrorView from './views/ErrorView';
import PrivacyPolicyView from './views/PrivacyPolicyView';
import DeleteDataView from './views/DeleteDataView';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";

const router = createBrowserRouter(
  createRoutesFromElements([
    <Route path="/" element={<MainView />} />,
    <Route path="privacy" element={<PrivacyPolicyView />} />,
    <Route path="delete-data" element={<DeleteDataView />} />,
    <Route path="*" element={<ErrorView />}/>,
  ])
);

function App() {
  return (
    <div className="App">
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
