import React, { Component } from 'react';
import Logo from '../logo.svg';

class ErrorView extends Component {
  render() {
    return (
      <div className="min-h-screen flex items-center justify-center bg-primary">
        <div className="bg-white rounded-2xl py-10 px-6 w-full max-w-xl shadow-md mx-4 my-6 text-center">
          <div className="flex items-center justify-center mb-5">
            <img className="w-20" src={Logo} alt="Kupl logo" />
          </div>

          <h1 className="text-7xl mb-3 font-bold text-slate-800">404</h1>

          <p>Oops, nothing here!</p>
        </div>
      </div>
    );
  }
}

export default ErrorView;