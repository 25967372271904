import React, { Component } from 'react';
import Logo from '../logo.svg';
import { Link } from "react-router-dom";
import { QuestionMarkCircleIcon } from '@heroicons/react/24/outline'
import { Tooltip } from 'react-tooltip'
import axios from 'axios';

const deviceOptions = [
  { label: "Please select an option", value: "placeholder" },
  { label: "Android", value: "android" },
  { label: "iPhone", value: "iphone" },
];

function deviceValue(value) {
  return deviceOptions.find((option) => option.value === value).label;
}

const discoveredChannels = [
  { label: "Please select an option", value: "placeholder" },
  { label: "Facebook", value: "option2" },
  { label: "Instagram", value: "option3" },
  { label: "TikTok", value: "option4" },
  { label: "Word of mouth", value: "option5" },
  { label: "Business card", value: "option6" },
  { label: "Invite from partner", value: "option7" },
  { label: "Other", value: "option8" },
];

function discoveredChannel(value) {
  return discoveredChannels.find((option) => option.value === value).label;
}

class MainView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      submitting: false,
      first_name: "",
      surname: "",
      email: "",
      city: "",
      discovered_channel: discoveredChannels[0].value,
      device: deviceOptions[0].value,
      termsAndConditionsAccepted: false,
      invalid_data_errors: {},
      alert: null,
    };
  }

  async handleSubmit() {
    this.setState({ submitting: true });
    var serverHost;
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
      serverHost = "http://localhost:8080";
    } else {
      serverHost = "https://kupl.herokuapp.com";
    }

    await axios.post(
      serverHost + "/early-access/sign-up/v2",
      {
        "first_name": this.state.first_name,
        "surname": this.state.surname,
        "email": this.state.email,
        "city": this.state.city,
        "device": deviceValue(this.state.device),
        "discovered_channel": discoveredChannel(this.state.discovered_channel),
      },
      { headers: { 'Content-Type': 'application/json' } }
    ).then((response) => {
      this.setState({
        submitting: false,
        first_name: "",
        surname: "",
        email: "",
        city: "",
        discovered_channel: discoveredChannels[0].value,
        device: deviceOptions[0].value,
        termsAndConditionsAccepted: false,
        invalid_data_errors: {},
        alert: {
          message: `Thanks for signing up, we can't wait to have you! We just sent an email to '${this.state.email}', keep an eye out for it as it contains important information on how to access the app :)`,
          type: "success",
        },
      });
      return response;
    }).catch((error) => {
      if (error.response.data.code === "VALIDATION_ERROR") {
        this.setState({ invalid_data_errors: error.response.data.invalid_data });
      } else {
        this.setState({
          alert: {
            message: error.response.data.message,
            type: "error",
          }
        });
      }
      return;
    });

    this.setState({ submitting: false });
  }

  handleInputChange(e) {
    this.setState({
      [e.target.id]: e.target.value,
      invalid_data_errors: {
        ...this.state.invalid_data_errors,
        [e.target.id]: null,
      },
    });
  }

  get canSubmit() {
    return this.state.submitting === false
      && this.state.termsAndConditionsAccepted
      && this.state.first_name.length > 0
      && this.state.surname.length > 0
      && this.state.email.length > 0
      && this.state.city.length > 0
      && this.state.device !== deviceOptions[0].value
      && this.state.discovered_channel !== discoveredChannels[0].value;
  }

  render() {
    return (
      <div className="min-h-screen flex items-center justify-center bg-primary">
        <div className="bg-white rounded-2xl py-10 px-6 max-w-xl shadow-md mx-4 my-6 text-center">
          <div className="flex items-center justify-center mb-4">
            <img className="w-20" src={Logo} alt="Kupl logo" />
          </div>

          <p className="text-gray-500 mb-2">Stop wondering what to do with your SO and start doing it! Strengthen your relationship with Kupl - discover unique date ideas and hidden treasures in your area, tailored to you and your situation.</p>
          <p className="text-gray-500 mb-1">Sign up for early access now, and let the memories start making themselves!</p>

          <hr className="h-px mx-12 my-6 bg-gray-200 border-0" />

          <h1 className="text-2xl mb-6 font-bold text-gray-900">Sign up for early access!</h1>

          {this.state.alert !== null ?
            <Alert message={this.state.alert.message} type={this.state.alert.type} /> : null
          }

          <div className="grid grid-rows-3 grid-cols-2 gap-x-4 gap-y-2">
            <div className="col-span-1">
              <TextInput
                id="first_name"
                label="First name"
                value={this.state.first_name}
                error={this.state.invalid_data_errors.first_name}
                onChange={(e) => this.handleInputChange(e)}
              />
            </div>

            <div className="col-span-1">
              <TextInput
                id="surname"
                label="Last name"
                value={this.state.surname}
                error={this.state.invalid_data_errors.surname}
                onChange={(e) => this.handleInputChange(e)}
              />
            </div>

            <div className="col-span-2">
              <TextInput
                id="email"
                label="Email used for App/Play Store"
                tooltip="You will not be able to download the app if you do not provide the right email here"
                value={this.state.email}
                error={this.state.invalid_data_errors.email}
                onChange={(e) => this.handleInputChange(e)}
              />
            </div>

            <div className="col-span-2 sm:col-span-1">
              <TextInput
                id="city"
                label="City/Town"
                value={this.state.city}
                error={this.state.invalid_data_errors.city}
                onChange={(e) => this.handleInputChange(e)}
              />
            </div>

            <div className="col-span-2 sm:col-span-1">
              <DropdownInput
                label="Mobile device"
                id="device"
                options={deviceOptions}
                selectedOption={this.state.device}
                error={this.state.invalid_data_errors.device}
                onChange={(e) => { this.handleInputChange(e) }}
              />
            </div>

            <div className="col-span-2">
              <DropdownInput
                label="Where did you hear about Kupl?"
                id="discovered_channel"
                options={discoveredChannels}
                selectedOption={this.state.discovered_channel}
                error={this.state.invalid_data_errors.discovered_channel}
                onChange={(e) => { this.handleInputChange(e) }}
              />
            </div>

            <div className="col-span-2 mt-1">
              <CheckboxInput
                id="termsAndConditionsAccepted"
                name="termsAndConditionsAccepted"
                text={
                  <p>I agree to the <b><Link to="privacy" className="text-primary hover:underline focus:ring focus:outline-none" >Privacy Policy</Link></b>.</p>
                }
                value={this.state.termsAndConditionsAccepted}
                onChange={(e) => {
                  this.setState({ termsAndConditionsAccepted: e.target.checked });
                }}
              />
            </div>
          </div>

          <PrimaryButton
            title="Sign up"
            disabled={!this.canSubmit}
            onClick={() => this.handleSubmit()}
          />
        </div>
      </div>
    );
  }
}

function getAlertColors(type) {
  switch (type) {
    case "error":
      return "bg-red-100 border-l-4 border-red-500 text-red-700";
    case "warning":
      return "bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700";
    case "success":
      return "bg-green-100 border-l-4 border-green-500 text-green-700";
    default:
      return "bg-blue-100 border-l-4 border-blue-500 text-blue-700";
  }
}

const Alert = ({ message, type }) => {
  return (
    <div class={`${getAlertColors(type)} px-4 py-3 my-5 rounded relative`} role="alert">
      <span class="block sm:inline">{message}</span>
    </div>
  );
}

const InputLabel = ({ id, label, tooltip }) => {
  return (
    <div className="flex">
      <label htmlFor={id} className="block text-sm font-medium leading-6 text-gray-900 text-start">{label}</label>
      {tooltip !== undefined ?
        <span className="ml-0.5 my-auto ">
          <a href="#" data-tooltip-id={`${id}-tooltip`}>
            <QuestionMarkCircleIcon className="h-4 w-4 text-primary" id={`${id}-tooltip-button`} />
          </a>
          <Tooltip id={`${id}-tooltip`} className="!max-w-sm" place="top">{tooltip}</Tooltip>
        </span> : null
      }
    </div>
  );
}

const TextInput = ({ label, placeholder, id, value, error, tooltip, onChange }) => {
  return (
    <div>
      <InputLabel id={id} label={label} tooltip={tooltip} />
      <input
        type="text"
        placeholder={placeholder}
        id={id}
        className="w-full rounded-lg bg-[#ECECEC] hover:bg-[#E0E0E0] p-2 focus:outline-none focus:ring transition-colors duration-200"
        value={value}
        onChange={onChange}
      />
      <p className="text-start text-xs mt-1 text-rose-600">{error}</p>
    </div>
  )
}

const DropdownInput = ({ label, id, options, selectedOption, error, tooltip, onChange }) => {
  return (
    <div>
      <InputLabel id={id} label={label} tooltip={tooltip} />
      <select
        value={selectedOption}
        id={id}
        onChange={onChange}
        className={`form-select w-full rounded-lg bg-[#ECECEC] hover:bg-[#E0E0E0] p-2 ${selectedOption === options[0].value ? "text-gray-500/50" : ""} focus:outline-none border-none focus:ring focus:ring-blue-500/50 transition-[background-color] duration-200`}
      >
        {
          options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))
        }
      </select>
      <p className="text-start text-xs mt-1 text-rose-600">{error}</p>
    </div>
  );
};

const CheckboxInput = ({ id, name, text, value, onChange }) => {
  return (
    <div className="flex items-center text-start">
      <input
        id={id}
        name={name}
        type="checkbox"
        value={value}
        checked={value}
        onChange={onChange}
        className="form-checkbox accent-primary w-4 h-4 text-primary bg-gray-100 border-gray-300 rounded focus:ring-blue-500/50 focus:ring-2"
      />
      <label
        htmlFor={id}
        className="ml-2 text-sm font-medium text-gray-600"
      >
        {text}
      </label>
    </div>
  );
};

const PrimaryButton = ({ title, onClick, disabled }) => {
  return (
    <button
      disabled={disabled}
      onClick={onClick}
      className="disabled:bg-primary/50 mt-8 bg-primary hover:bg-[#E49590] focus:outline-none focus:ring text-white rounded-lg py-2 px-12 transition-colors duration-200"
    >
      {title}
    </button>
  );
}

export default MainView;