import React, { Component } from 'react';
import Logo from '../logo.svg';

class DeleteDataView extends Component {
  render() {
    return (
      <div className="min-h-screen flex items-center justify-center bg-primary">
        <div className="bg-white rounded-2xl py-10 px-6 w-full max-w-xl shadow-md mx-4 my-6 text-center">
          <div className="flex items-center justify-center mb-5">
            <img className="w-20" src={Logo} alt="Kupl logo" />
          </div>

          <h1 className="text-xl mb-3 font-bold text-slate-800">Request Account Deletion</h1>

          <p>If you wish to have your account deleted, you can do so through the settings menu in the app. Otherwise, you can submit your request to us at <b>support@kupl.app</b>.</p>
        </div>
      </div>
    );
  }
}

export default DeleteDataView;